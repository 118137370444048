import React, { useCallback, useEffect, useState } from 'react';
import SortableTree, {
  changeNodeAtPath, getVisibleNodeCount, map
} from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import {
  GenericListFilters, SelectField, SkeletonSection, Wrapper
} from 'components/index';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import {
  ActivityService, CompanyService, StructureLevelService
} from 'services';
import { PageTitle } from 'pages';
import {
  Card,
  Grid
} from '@material-ui/core';
import { translate } from 'utils';
import { TreeNodeButtons, TreeNodeContent } from 'components/StructureLevel';
import { useFetch } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import { API_ROUTES, ROUTES } from 'utils/constants';

const StyledSelectLoader = styled.div`
  height: 56px;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const minNodeSize = 5;

const StructureLevelTree = () => {
  const { companyId } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isTreeLoading, setIsTreeLoading] = useState(true);
  const [activity, setActivity] = useState(null);
  const [parentCompany, setParentCompany] = useState(null);
  const [tree, setTree] = useState([]);
  const { response: activities, isLoading: isActivityLoading } = useFetch(ActivityService.getAllActivities, []);
  const [modifiedTree, setModifiedTree] = useState(null);
  const [companyName, setCompanyName] = useState('...');
  const [search, setSearch] = useState('');
  const [nodeSize, setNodeSize] = useState(minNodeSize);
  const getNodeKey = data => data.node.key;
  const displayedTree = modifiedTree || tree;

  const formatTree = useCallback(notFormattedTree => map({
    treeData: notFormattedTree,
    getNodeKey: node => node.value,
    callback: ({ node }) => ({ ...node, title: () => <TreeNodeContent node={node} />, expanded: true }),
    ignoreCollapsed: false
    // eslint-disable-next-line
  }), []);

  const updateNode = useCallback(({ treeData, newNode, path }) => formatTree(changeNodeAtPath({
    treeData, path, newNode, getNodeKey, ignoreCollapsed: false
  })), [formatTree]);

  const searchMethod = ({ node, searchQuery }) => searchQuery && node?.label?.toLowerCase().includes(searchQuery.toLowerCase());

  const getParentCompany = useCallback(() => {
    CompanyService.getParentCompany(companyId)
      .then(resp => setParentCompany(resp))
      .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
  }, [companyId, enqueueSnackbar]);

  const reloadCompanyTree = useCallback(() => {
    if (activity) {
      setIsTreeLoading(true);
      CompanyService.getCompanyTree(companyId, activity.value)
        .then(resp => {
          setTree(formatTree([resp]));
          setModifiedTree(null);
          getParentCompany();
        })
        .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
        .finally(() => setIsTreeLoading(false));
    }
  }, [companyId, enqueueSnackbar, formatTree, activity, getParentCompany]);

  const setChildCompany = useCallback(selectedCompany => {
    history.push(API_ROUTES.COMPANY_TREE_DETAILS(selectedCompany.value));
  }, [history]);

  const onMoveNode = useCallback(data => {
    if (data.path.toString() !== data.prevPath.toString()) {
      setIsTreeLoading(true);
      const moveData = {
        movedEntityId: data.node.value,
        movedEntityType: data.node.type,
        newParentEntityId: data.nextParentNode.value,
        newParentEntityType: data.nextParentNode.type,
        activity: activity.value
      };
      StructureLevelService.moveStructureLevel(moveData)
        .then(resp => {
          enqueueSnackbar(translate('confirms.structureLevelTree.moved'), { variant: 'success' });
          const newNode = formatTree([{ ...resp, expanded: data.node.expanded, canEdit: data.node.canEdit }])[0];
          setTree(updateNode({ treeData: data.treeData, newNode, path: data.path }));
        })
        .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
        .finally(() => {
          setIsTreeLoading(false);
          setModifiedTree(null);
        });
    }
  }, [enqueueSnackbar, formatTree, updateNode, activity]);

  const onTreeUpdated = () => {
    setIsTreeLoading(false);
    setModifiedTree(null);
  };

  const onCreate = ({
    newNode, confirmLabel, parentNode, parentPath
  }) => {
    const newChildren = [newNode, ...parentNode.children];
    const newParent = { ...parentNode, children: newChildren };
    setTree(updateNode({ treeData: displayedTree, newNode: newParent, path: parentPath }));
    enqueueSnackbar(confirmLabel, { variant: 'success' });
  };

  const onUpdate = ({ newNode, confirmLabel, path }) => {
    setTree(updateNode({ treeData: displayedTree, newNode, path }));
    enqueueSnackbar(confirmLabel, { variant: 'success' });
  };

  const onDelete = ({ node }) => {
    enqueueSnackbar(translate('confirms.structureLevel.delete', { name: node.label }), { variant: 'success' });
    reloadCompanyTree();
  };

  const displayParentCompanyBlock = () => Boolean(parentCompany) && (
  <Grid item>
    <Grid container direction="row" justifyContent="center" spacing={4}>
      <Grid item>
        <Card style={{
          height: 56, padding: 10, display: 'flex', border: 'solid #bbb 1px'
        }}
        >
          <Grid alignItems="center" container direction="row" spacing={3}>
            <Grid item>
              <TreeNodeContent node={parentCompany.parentCompany} />
            </Grid>
            <Grid item>
              <TreeNodeButtons
                activity={activity}
                companyId={companyId}
                node={parentCompany.parentCompany}
                setLoading={setIsTreeLoading}
                onCreate={() => {
                  enqueueSnackbar(translate('confirms.companyList.create'), { variant: 'success' });
                  reloadCompanyTree();
                }}
                onParentCompanyChanged={reloadCompanyTree}
                onTreeUpdated={onTreeUpdated}
                onUpdate={() => {
                  enqueueSnackbar(translate('confirms.companyList.update'), { variant: 'success' });
                  reloadCompanyTree();
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <SelectField
          height={56}
          isClearable={false}
          label="common.selectChildCompany"
          name="select-child-company"
          options={parentCompany.childCompanies}
          value={parentCompany.childCompanies.find(childCompany => childCompany.value === companyId)}
          width="100%"
          onChange={setChildCompany}
        />
      </Grid>
    </Grid>
  </Grid>
  );

  useEffect(() => {
    activities && activities[0] && setActivity(activities[0]);
  }, [activities]);

  useEffect(() => {
    reloadCompanyTree();
  }, [reloadCompanyTree, activity]);

  useEffect(() => {
    setCompanyName(tree[0]?.label || '...');
  }, [tree]);

  useEffect(() => {
    setNodeSize(Math.max(getVisibleNodeCount({ treeData: displayedTree }), minNodeSize));
  }, [displayedTree]);

  return (
    <Wrapper>
      <PageTitle
        title={translate('institutionGroupTree.title', { company: companyName })}
        onGoBack={() => history.push(ROUTES.COMPANY_LIST)}
      />
      <Grid container direction="column" spacing={1}>
        {displayParentCompanyBlock()}
        <Grid alignItems="center" container direction="row" item spacing={4}>
          <Grid item style={{ marginBottom: '1rem' }} xs={8}>
            <GenericListFilters
              search={search}
              setSearch={setSearch}
              tooltipInfo="institutionGroupTree.tooltip"
            />
          </Grid>
          <Grid item xs>
            {(isActivityLoading
              ? (
                <StyledSelectLoader>
                  <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                </StyledSelectLoader>
              ) : (
                <SelectField
                  height={56}
                  isClearable={false}
                  label="common.selectActivity"
                  name="select-activity"
                  options={activities}
                  value={activity}
                  onChange={setActivity}
                />
              )
              )}
          </Grid>
        </Grid>
      </Grid>
      <div style={{ height: `${(nodeSize * 62) + 5}px`, position: 'relative', marginLeft: 12 }}>
        <SortableTree
          canDrag={node => node.node.canEdit}
          canDrop={data => Boolean(data.nextParent)}
          generateNodeProps={({ node, path }) => ({
            buttons: [
              <TreeNodeButtons
                activity={activity}
                companyId={companyId}
                node={node}
                path={path}
                setLoading={setIsTreeLoading}
                onCreate={onCreate}
                onDelete={onDelete}
                onParentCompanyChanged={reloadCompanyTree}
                onTreeUpdated={onTreeUpdated}
                onUpdate={onUpdate}
              />
            ]
          })}
          getNodeKey={getNodeKey}
          placeholderRenderer={() => <></>}
          searchMethod={searchMethod}
          searchQuery={search}
          treeData={displayedTree}
          onChange={setModifiedTree}
          onMoveNode={onMoveNode}
          onVisibilityToggle={data => setTree(data.treeData)}
        />
        {isTreeLoading && <SkeletonSection backgroundColor="rgba(0,0,0,0)" style={{ backdropFilter: 'blur(3px)' }} />}
      </div>
    </Wrapper>
  );
};

export default StructureLevelTree;
